<template>
  <v-container fluid pa-0>
    <div
      v-if="!$store.state.Company.company.subscription_id"
      class="mt-4 empty-state"
    >
      <v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <TicketMain
        v-if="$store.state.Company.company.subscription_id && viewTickets"
      />
    </div>
  </v-container>
</template>

<script>
import TicketMain from "./ticket-main.vue";
import UserDataMixin from "@/mixins/UserData";
export default {
  name: "Ticket",
  mixins: [UserDataMixin],
  data() {
    return {
      createTicketButton: false,
    };
  },
  components: {
    TicketMain,
  },
  computed: {
    viewTickets() {
      return (
        this.$store.state.Company &&
        this.$store.state.Company.company &&
        this.$store.state.Company.company.options &&
        this.$store.state.Company.company.options.rolesAndPermissions &&
        this.checkPermission("viewTickets")
      );
    },
  },
  watch: {
    viewTickets() {
      if (!this.viewTickets) this.$router.push("/createTicket").catch();
    },
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.empty-state {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
