<template>
  <v-container fluid pa-0>
    <div v-if="!isTicketDetails">
      <v-row v-if="ticketSuccessAlert && !$vuetify.breakpoint.mdAndUp">
        <v-alert
          dismissible
          close-icon="mdi-close"
          text
          icon="mdi-check-circle-outline"
          dense
          @input="closeTicketAlert"
          type="success"
        >
          <span class="my-auto font-weight-regular text-subtitle-2">{{
            ticketAlertMessage
          }}</span>
        </v-alert>
      </v-row>

      <v-row>
        <v-col
          :cols="$vuetify.breakpoint.mdAndUp ? '' : '7'"
          class="d-flex wrap text-h5 font-weight-medium"
          :class="$vuetify.breakpoint.mdAndUp ? 'pl-8' : 'pl-5'"
        >
          {{ text === "map" ? $t("ticketsMap") : $t("tickets") }}
        </v-col>
        <!-- <v-spacer></v-spacer> -->
        <v-col
          v-if="ticketSuccessAlert && $vuetify.breakpoint.mdAndUp"
          class="d-flex justify-end align-center pt-2"
        >
          <v-alert
            dismissible
            close-icon="mdi-close"
            text
            icon="mdi-check-circle-outline"
            dense
            @input="closeTicketAlert"
            type="success"
          >
            <span class="my-auto font-weight-regular text-subtitle-2">{{
              ticketAlertMessage
            }}</span>
          </v-alert>
        </v-col>
        <v-col v-else class="d-flex justify-end align-center pt-2 pb-5">
          <v-btn
            v-if="
              checkPermission('createTickets') && $vuetify.breakpoint.mdAndUp
            "
            color="primary"
            class="font-weight-regular text-none rounded-lg"
            @click="createTicket"
            ><v-icon>mdi-plus</v-icon> {{ $t("createTicket") }}</v-btn
          >
          <v-btn-toggle
            v-if="checkPermission('mapView')"
            :borderless="false"
            v-model="text"
            mandatory
            :class="$vuetify.breakpoint.mdAndUp ? 'mr-5' : 'mr-2'"
            class="d-flex justify-end ml-5 rounded-lg"
          >
            <v-btn
              height="36px"
              value="ticket"
              :outlined="text !== 'ticket'"
              aria-label="Ticket View Button"
              color="primary"
            >
              <v-icon size="20" :color="text !== 'ticket' ? 'primary' : 'white'"
                >mdi-land-rows-horizontal</v-icon
              >
            </v-btn>
            <v-btn
              height="36px"
              value="map"
              :outlined="text !== 'map'"
              aria-label="Map View Button"
              color="primary"
            >
              <v-icon size="20" :color="text !== 'map' ? 'primary' : 'white'"
                >mdi-map-outline</v-icon
              >
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <!-- <v-col class="mb-2 ml-auto d-flex justify-end"> -->

        <!-- </v-col> -->
      </v-row>
      <ViewTicket @onTicketDetails="onTicketDetails" v-if="text === 'map'" />
      <div v-if="text === 'ticket'">
        <v-row>
          <v-app-bar
            elevation="0"
            color="white"
            class="appbar-header"
            height="40"
          >
            <v-tabs>
              <v-tab
                v-for="(key, index) in tabs"
                :key="index"
                class="text-none tab-bar"
                :class="$vuetify.breakpoint.mdAndUp ? 'pl-8' : 'pl-5'"
                @change="currentTab = key"
                >{{ key
                }}<v-chip
                  v-if="key === 'My Tickets'"
                  class="ma-2 pa-1"
                  color="primary"
                  outlined
                  pill
                  small
                >
                  {{ totalItems }}
                </v-chip></v-tab
              >
            </v-tabs>
          </v-app-bar>
        </v-row>
        <div v-if="currentTab === 'My Tickets'" class="mt-4">
          <v-card
            elevation="0"
            width="100%"
            :class="$vuetify.breakpoint.smAndDown ? 'mb-12' : null"
          >
            <div v-if="tickets.length > 0 || filteredTable">
              <v-row class="mt-0" :no-gutters="this.$vuetify.breakpoint.xs">
                <!-- Ticket Table -->
                <v-col v-if="this.$vuetify.breakpoint.mdAndUp">
                  <v-data-table
                    id="main-tickets-table"
                    v-model="selected"
                    fixed-header
                    :headers="headers"
                    :options.sync="options"
                    :loading="loading"
                    :items="tickets"
                    item-key="ticket_id"
                    :height="`calc(100vh - 270px)`"
                    color="primary"
                    checkbox-color="primary"
                    :server-items-length="totalItems"
                    :items-per-page="!$vuetify.breakpoint.mdAndUp ? -1 : 15"
                    :class="$vuetify.breakpoint.mdAndUp ? 'ml-2' : null"
                    :hide-default-footer="!$vuetify.breakpoint.mdAndUp"
                    :loading-text="$t('loading')"
                    :footer-props="{
                      'items-per-page-options': perPageChoices,
                    }"
                  >
                    <template
                      v-if="$vuetify.breakpoint.mdAndUp"
                      v-slot:[`body.prepend`]
                    >
                      <tr>
                        <!-- Select All -->
                        <!-- Hide this toolbar checkbox until feature is complete, remember to add show select above -->
                        <!-- <td></td> -->
                        <!-- Excavation Date -->
                        <td>
                          <v-text-field
                            v-model="filters.start_date"
                            type="Date"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('start_date')"
                          ></v-text-field>
                        </td>
                        <!-- TicketRevision -->
                        <td>
                          <v-text-field
                            v-model="filters.center_ticket_id"
                            type="text"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('center_ticket_id')"
                          ></v-text-field>
                        </td>
                        <!-- SiteContactName -->
                        <td>
                          <v-text-field
                            v-model="filters.contact"
                            type="text"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('contact')"
                          ></v-text-field>
                        </td>
                        <!-- SiteContactPhone -->
                        <td>
                          <v-text-field
                            v-model="filters.contact_phone"
                            type="text"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('contact_phone')"
                          ></v-text-field>
                        </td>
                        <!-- WorkDoneFor -->
                        <td>
                          <v-text-field
                            v-model="filters.done_for"
                            type="text"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('done_for')"
                          ></v-text-field>
                        </td>
                        <!-- Priority -->
                        <td>
                          <v-text-field
                            v-model="filters.priority"
                            type="text"
                            class="upperCase"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('priority')"
                          ></v-text-field>
                        </td>
                        <!-- Ticket Type -->
                        <td>
                          <v-text-field
                            v-model="filters.type"
                            type="text"
                            class="upperCase"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('type')"
                          ></v-text-field>
                        </td>
                        <!-- Status -->
                        <td>
                          <!-- <v-text-field v-model="filters.status" type="text" clear-icon="mdi-close-circle" clearable></v-text-field> -->
                        </td>
                        <!-- Expiration Date -->
                        <td>
                          <v-text-field
                            v-model="filters.expiration_date"
                            type="Date"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('expiration_date')"
                          ></v-text-field>
                        </td>
                        <!-- User -->
                        <td>
                          <v-select
                            v-model="selectUserId"
                            :items="users"
                            item-text="name"
                            item-value="user_id"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="selectClear('user_id')"
                          ></v-select>
                        </td>
                        <!-- Center -->
                        <td>
                          <v-select
                            v-model="selectCenterId"
                            :items="centers"
                            item-text="center_name"
                            item-value="center_id"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="selectClear('center_id')"
                          ></v-select>
                        </td>
                        <!-- Address -->
                        <td>
                          <v-text-field
                            v-model="filters.street_address"
                            type="text"
                            class="upperCase"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('street_address')"
                          ></v-text-field>
                        </td>
                        <!-- Place -->
                        <td>
                          <v-text-field
                            v-model="filters.place"
                            type="text"
                            class="upperCase"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('place')"
                          ></v-text-field>
                        </td>
                        <!-- County -->
                        <td>
                          <v-text-field
                            v-model="filters.county"
                            type="text"
                            class="upperCase"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('county')"
                          ></v-text-field>
                        </td>
                        <!-- State -->
                        <td>
                          <v-text-field
                            v-model="filters.state"
                            type="text"
                            class="upperCase"
                            clear-icon="mdi-close-circle"
                            clearable
                            @change="ticketSearch"
                            @click:clear="filterChange('state')"
                          ></v-text-field>
                        </td>
                      </tr>
                    </template>
                    <!-- Hide this toolbar checkbox until feature is complete -->
                    <!-- <template v-slot:[`header.data-table-select`]="{ props, on }">
                  <v-simple-checkbox
                    v-ripple
                    :value="props.value || props.indeterminate"
                    v-on="on"
                    :indeterminate="props.indeterminate"
                    color="primary"
                    :class="$vuetify.breakpoint.mdAndUp ? '':'ticket-mobile-checkbox'"
                  />
                </template>       -->
                    <template v-slot:[`item.contact`]="{ item }">
                      <div class="nowrap">
                        {{ handleEmptyData(item.contact) }}
                      </div>
                    </template>
                    <template v-slot:[`item.contact_phone`]="{ item }">
                      <div class="nowrap">
                        {{ handleEmptyData(item.contact_phone) }}
                      </div>
                    </template>
                    <template v-slot:[`item.done_for`]="{ item }">
                      <div class="nowrap">
                        {{ handleEmptyData(item.done_for) }}
                      </div>
                    </template>
                    <template v-slot:[`item.start_date`]="{ item }">
                      <div class="nowrap">
                        {{ formatTicketDate(item.start_date) }}
                      </div>
                    </template>
                    <template v-slot:[`item.fullAddress`]="{ item }">
                      <div class="nowrap">{{ item.fullAddress }}</div>
                    </template>
                    <template v-slot:[`item.center_ticket_id`]="{ item }">
                      <!-- <router-link
                    :class="ticketRevisionClass()"
                    :to="'/tickets'"

                > -->
                      <a
                        class="nowrap primary--text"
                        @click="onTicketDetails(item)"
                      >
                        {{ item.center_ticket_id }} - {{ item.revision }}
                      </a>
                      <!-- </router-link> -->
                    </template>
                    <template v-slot:[`item.priority`]="{ item }">
                      <div class="nowrap ma-2">
                        <v-icon
                          x-small
                          :color="priorityIconColor(item.priority)"
                        >
                          {{ priorityIcon(item.priority) }}
                        </v-icon>
                        {{ item.priority }}
                      </div>
                    </template>
                    <template v-slot:[`item.ticket_type`]="{ item }">
                      {{ item.ticket_type }}
                    </template>
                    <template v-slot:[`item.state`]="{ item }">
                      <div class="nowrap">{{ item.state }}</div>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <div v-if="supportsResponses(item)">
                        <v-btn
                          text
                          small
                          color="primary"
                          @click="
                            $vuetify.breakpoint.mdAndUp
                              ? showStatusPanel(item)
                              : showStatusDialog(item)
                          "
                        >
                          <v-icon
                            left
                            x-small
                            :color="
                              calculateResponsePercentage(item).value === 100
                                ? 'green'
                                : 'red'
                            "
                          >
                            mdi-circle
                          </v-icon>
                          {{ responseStatus(item) }}
                        </v-btn>
                      </div>
                      <div v-else>
                        <v-btn
                          text
                          small
                          color="primary"
                          @click="
                            $vuetify.breakpoint.mdAndUp
                              ? showStatusPanel(item)
                              : showStatusDialog(item)
                          "
                        >
                          View Members
                        </v-btn>
                      </div>
                    </template>
                    <template v-slot:[`item.expiration_date`]="{ item }">
                      <div class="nowrap">
                        {{ formatTicketDate(item.expiration_date) }}
                      </div>
                    </template>
                    <template v-slot:[`item.street_address`]="{ item }">
                      <div class="nowrap">{{ item.street_address }}</div>
                    </template>
                    <template v-slot:[`item.user_id`]="{ item }">
                      <div class="nowrap">{{ getUserName(item.user_id) }}</div>
                    </template>
                    <template v-slot:[`item.place`]="{ item }">
                      <div class="nowrap">{{ item.place }}</div>
                    </template>
                    <template v-slot:[`item.county`]="{ item }">
                      <div class="nowrap">{{ item.county }}</div>
                    </template>
                    <template v-slot:[`item.center_id`]="{ item }">
                      <div class="nowrap">
                        {{ getCenterName(item.center_id) }}
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col v-if="this.$vuetify.breakpoint.smAndDown" class="pb-14">
                  <v-data-iterator
                    id="ticket-cards"
                    :items="tickets"
                    item-key="ticket_id"
                    fixed-header
                    show-select
                    loading-text="Loading items..."
                    no-data-text=""
                    :page.sync="options.page"
                    :loading="loading"
                    :options.sync="options"
                    :items-per-page="options.itemsPerPage"
                    @toggle-select-all="mobileSelectAll"
                  >
                    <template v-slot:header>
                      <TicketsMobileDataToolbar
                        :display-toolbar="!!tickets.length"
                        :selected-tickets="selected"
                      >
                        <template v-slot:selectCheckbox>
                          <v-checkbox v-model="mobileSelectAll" />
                        </template>
                      </TicketsMobileDataToolbar>
                    </template>
                    <template v-slot:default="props">
                      <TicketsMobileCard
                        v-for="item in props.items"
                        :key="item.ticket_id"
                        :item="item"
                        :is-selected="
                          !!selected.find(
                            (ticket) => ticket.ticket_id === item.ticket_id
                          )
                        "
                        :supports-responses="supportsResponses(item)"
                        @showStatusDialog="showStatusDialog"
                        @onTicketDetails="onTicketDetails"
                      >
                        <template v-slot:selectCheckbox>
                          <v-checkbox
                            v-model="selected"
                            :value="item"
                            :multiple="true"
                            :value-comparator="setMobileCheckBoxState"
                          />
                        </template>
                      </TicketsMobileCard>
                    </template>
                  </v-data-iterator>
                </v-col>
              </v-row>
            </div>
          </v-card>

          <div
            class="mt-4 empty-state"
            v-if="
              !loading && tickets.length === 0 && !filteredTable && checkPlan
            "
          >
            <v-card
              elevation="0"
              width="100%"
              :class="$vuetify.breakpoint.smAndDown ? 'mb-12' : null"
            >
              <v-img
                height="auto"
                src="@/assets/images/ticket-empty-state.svg"
                max-width="100"
                min-width="100"
                class="mx-auto my-2"
              >
              </v-img>
              <v-row>
                <span
                  class="h6 font-weight-medium grey--text text--darken-4 mx-auto my-2"
                >
                  {{ $t("noTicketsCreated") }}
                </span>
              </v-row>
              <v-row>
                <span
                  class="subtitle-2 font-weight-regular grey--text text--darken-2 mx-auto text-center"
                  style="display: block"
                >
                  <template v-if="$vuetify.breakpoint.mdAndUp">
                    Click the create ticket button to create your first ticket.
                  </template>
                  <template v-else>
                    Click the create ticket button to <br />
                    create your first ticket.
                  </template>
                </span>
              </v-row>
            </v-card>
          </div>
        </div>
        <div v-if="currentTab == 'Approval Queue'" class="mt-4">
          <PendingQueue />
        </div>
        <div v-if="currentTab == 'Awaiting Approval'" class="mt-4">
          <AwaitingQueue @createTicket="createTicket" />
        </div>
        <ScheduledTicketQueue
          v-if="currentTab === 'Scheduled Tickets'"
          @createTicket="createTicket"
        />
        <SuspendTicketQueue
          v-if="currentTab == 'Suspend Tickets'"
          class="mt-4"
        />
      </div>
      <v-bottom-navigation
        v-if="$vuetify.breakpoint.smAndDown && checkPermission('createTickets')"
        v-model="bottomNavbar"
        fixed
        horizontal
        height="70"
        class="px-2 py-4"
      >
        <v-row class="px-4 py-2">
          <v-btn
            color="primary"
            class="text-none rounded-lg font-weight-regular text-h6 white--text"
            block
            height="50"
            @click="createTicket"
            >{{ $t("createTicket") }}<v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </v-bottom-navigation>
    </div>
    <TicketDetails
      @createTicket="createTicket"
      :ticket-details="ticketRowData"
      @hideTicketDetails="hideTicketDetails"
      @secondaryFnSuccessAlert="secondaryFnSuccessAlert"
      v-else
    />
    <v-layout row justify-center v-if="responsesDialog">
      <v-dialog v-model="responsesDialog" fullscreen hide-overlay>
        <MembersPanel
          :ticket="selectedTicket"
          :show-responses="showResponses"
          @hideTicketResponses="hideTicketResponses"
        >
        </MembersPanel>
      </v-dialog>
    </v-layout>
    <v-layout row justify-end v-if="responsesPanel">
      <div id="responsePanel">
        <MembersPanel
          :ticket="selectedTicket"
          :show-responses="showResponses"
          @hideTicketResponses="hideTicketResponses"
        >
        </MembersPanel>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import { formatTicketDateTime } from "@/store/utils/utils.js";
import api from "../../../store/api";
import UserDataMixin from "@/mixins/UserData";
import MemberMixin from "@/mixins/MemberMixin";
export default {
  name: "TicketMain",
  mixins: [UserDataMixin, MemberMixin],
  components: {
    ScheduledTicketQueue: () => import("./ScheduledTicketQueue"),
    AwaitingQueue: () => import("./approval/AwaitingQueue.vue"),
    PendingQueue: () => import("./approval/PendingQueue.vue"),
    TicketsMobileCard: () => import("./TicketsMobileCard"),
    TicketsMobileDataToolbar: () => import("./TicketsMobileDataToolbar"),
    MembersPanel: () => import("@/components/members/MembersPanel"),
    SuspendTicketQueue: () => import("./SuspendTicketQueue"),
    ViewTicket: () => import("./ViewTicket.vue"),
    TicketDetails: () => import("./TicketDetails.vue"),
  },
  props: {},
  data() {
    return {
      currentTab: "My Tickets",
      createTicketButton: false,
      ticketSuccessAlert: false,
      ticketAlertMessage: "",
      bottomNavbar: true,
      selected: [],
      tickets: [],
      totalItems: 0,
      loading: false,
      showTicketResponses: false,
      responsesDialog: false,
      responsesPanel: false,
      isTicketDetails: false,
      ticketRowData: {},
      options: {
        sortDesc: [true],
        sortBy: ["created_at"],
        page: 1,
        itemsPerPage: 15,
      },
      filters: {
        start_date: "",
        center_ticket_id: "",
        priority: "",
        type: "",
        expiration_date: "",
        status: "",
        center_id: "",
        street_address: "",
        place: "",
        county: "",
        state: "",
        user_id: "",
        contact: "",
        contact_phone: "",
        done_for: "",
      },
      text: "ticket",
      filteredTable: false,
      selectUserId: { name: "", user_id: "" },
      selectCenterId: { center_name: "", center_id: "" },
      headers: [
        { text: this.$t("startDate"), value: "start_date" },
        {
          text: this.$t("ticket"),
          align: "start",
          value: "center_ticket_id",
        },
        { text: "Site Contact Name", align: "start", value: "contact" },
        { text: "Site Contact Phone", align: "start", value: "contact_phone" },
        { text: "Work done for", align: "start", value: "done_for" },
        { text: this.$t("priority"), align: "start", value: "priority" },
        { text: this.$t("type"), value: "type" },
        { text: this.$t("responseStatus"), value: "status", sortable: false },
        { text: this.$t("expirationDate"), value: "expiration_date" },
        { text: this.$t("user"), value: "user_id" },
        { text: this.$t("811Center"), value: "center_id" },
        { text: this.$t("address"), value: "street_address" },
        { text: this.$t("place"), value: "place" },
        { text: this.$t("county"), value: "county" },
        { text: this.$t("state"), value: "state" },
      ],
      centers: [],
      users: [],
      selectedTicket: {},
      showResponses: false,
      dynamicCol: 12,
      responsesCol: 0,
      perPageChoices: [5, 10, 15, 20],
      timeOut: null,
    };
  },
  computed: {
    subscriptionPlan() {
      return (
        this.$store.state.Company.company.subscription_id ===
        "00000000-0000-0000-0000-000000000000"
      );
    },
    tabs() {
      const allTabs = [
        { name: "My Tickets", permission: "viewTickets" },
        {
          name: "Awaiting Approval",
          permission: "viewTickets",
          checkApprover: true,
        },
        { name: "Approval Queue", permission: "approveTickets" },
        {
          name: "Scheduled Tickets",
          permission: "scheduleTickets",
          checkApprover: true,
        },
      ];
      const activeTabs = [];
      //Does the company have any centers that have suspense tickets enabled?
      const additionalCenters =
        this.$store.state.Company.company.additionalCenters || [];
      const allCenters = [
        ...this.$store.state.Company.company.centers,
        ...additionalCenters,
      ];
      if (allCenters.length) {
        const companyCenters = this.$store.state.Company.company.centers;
        if (companyCenters) {
          let isSuspendTabEnabled = false;
          companyCenters.forEach((center) => {
            // find the center in the user's centers
            const userCenter = allCenters.find(
              (c) => c.center_id === center.center_id
            );
            // if the user has access to the center and the center has suspend tickets enabled
            if (
              userCenter.options.newtin2?.suspend_converts?.length > 0 &&
              !isSuspendTabEnabled
            ) {
              allTabs.push({
                name: "Suspend Tickets",
                permission: "viewTickets",
              });
              isSuspendTabEnabled = true;
            }
          });
        }
        const persona = this.$store.state.User.user.persona;
        const options = this.$store.state.Company.company.options;
        if (options) {
          const rolesAndPermissions = options.rolesAndPermissions;
          allTabs.forEach((tab) => {
            if (rolesAndPermissions[persona].includes(tab.permission)) {
              if (tab.checkApprover) {
                if (
                  !this.$store.state.User.user.approver_id &&
                  tab.permission !== "scheduleTickets"
                ) {
                  return;
                } else if (
                  this.$store.state.User.user.approver_id &&
                  tab.permission === "scheduleTickets"
                )
                  return;
              }
              activeTabs.push(tab.name);
            }
          });
        }
      }

      return activeTabs;
    },
    mobileSelectAll: {
      set(newValue) {
        this.selected = newValue ? [...this.tickets] : [];
      },
      get() {
        const ticketCount = this.tickets.length;
        return ticketCount > 0 && ticketCount === this.selected.length;
      },
    },
  },
  watch: {
    ticketSuccessAlert(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.ticketSuccessAlert = false;
        }, 8000);
      }
    },
    currentTab() {
      if (this.currentTab === "" || !this.currentTab)
        this.currentTab = "My Tickets";
    },
    options: {
      handler(newVal, oldVal) {
        if (
          newVal.itemsPerPage === oldVal.itemsPerPage &&
          newVal.page === oldVal.page &&
          newVal.sortBy[0] === oldVal.sortBy[0] &&
          newVal.sortDesc[0] === oldVal.sortDesc[0] &&
          !this.ticketSuccessAlert
        ) {
          return;
        }
        this.timeOut && clearTimeout(this.timeOut);
        this.timeOut = setTimeout(() => {
          this.getTickets(this.filters);
        }, 200);
      },
      deep: true,
    },
    tabs() {
      if (this.tabs.length === 0 && this.checkPermission("createTickets"))
        this.createTicketButton = true;
      else this.createTicketButton = false;
    },
  },
  async created() {
    this.$store.commit("setIsEdit", "");
    if (this.tabs.length === 0) this.createTicketButton = true;
    else this.createTicketButton = false;
  },
  async mounted() {
    this.getCenters();
    this.getUsers();
    this.getTickets(this.filters);
  },
  methods: {
    hideTicketDetails() {
      this.isTicketDetails = false;
    },
    closeTicketAlert() {
      this.ticketSuccessAlert = false;
    },
    secondaryFnSuccessAlert(ticketNumber, ticketRevision, type) {
      if (type === "cancelled") {
        this.ticketAlertMessage = `Ticket No. ${ticketNumber}-${ticketRevision} has been cancelled!`;
      }
      if (type === "commented") {
        this.ticketAlertMessage = `Ticket No. ${ticketNumber}-${ticketRevision}- comment added Successfully!`;
      }
      if (type === "retransmitted") {
        this.ticketAlertMessage = `Ticket No. ${ticketNumber}-${ticketRevision} has been Retransmitted Successfully!`;
      }
      if (type === "repeat") {
        this.ticketAlertMessage = `Ticket No. ${ticketNumber}-${ticketRevision} has been Repeated Successfully!`;
      }
      if (type === "remark") {
        this.ticketAlertMessage = `Ticket No. ${ticketNumber}-${ticketRevision} has been Remarked Successfully!`;
      }
      if (type === "3HourNotice") {
        this.ticketAlertMessage = `3-hour notice Ticket Created Successfully!`;
      }
      if (type === "3HourDeepNotice") {
        this.ticketAlertMessage = `3-hour deep notice Ticket Created Successfully!`;
      }
      if (type === "update") {
        this.ticketAlertMessage = `Update Ticket Created Successfully!`;
      }
      if (type === "extend") {
        this.ticketAlertMessage = `Ticket No. ${ticketNumber}-${ticketRevision} has been Extended Successfully!`;
      }
      if (type === "secondNotice") {
        this.ticketAlertMessage = `Second notice Ticket Created Successfully!`;
      }
      if (type === "correction") {
        this.ticketAlertMessage = `Correction Ticket Created Successfully!`;
      }
      if (type === "utilityNotFound") {
        this.ticketAlertMessage = `Utility Not Found Ticket Created Successfully!`;
      }
      if (type === "addMembers") {
        this.ticketAlertMessage = `Members added Successfully!`;
      }
      if (type === "relocateRefresh") {
        this.ticketAlertMessage = `Ticket No. ${ticketNumber}-${ticketRevision} has been Relocate Refresh Successfully!`;
      }
      if (type === "noResponse") {
        this.ticketAlertMessage = `No Response Ticket Created Successfully!`;
      }
      this.isTicketDetails = false;
      this.ticketSuccessAlert = true;
    },
    onTicketDetails(item) {
      if (this.checkPermission("viewTickets")) {
        this.hideTicketResponses(); // hide ticket responses if they are open
        this.isTicketDetails = true;
        this.ticketRowData = item;
      }
    },
    checkPlan() {
      if (this.subscriptionPlan) {
        this.createTicketButton = true;
        return false;
      } else return true;
    },
    createTicket() {
      this.$router.push("/createTicket").catch();
    },
    async cancelTicket() {
      this.currentTab = "My Tickets";
      this.createTicketButton = false;
      await this.$store.commit("setCurrentStep", 1);
      if (this.tabs.length === 0 && this.subscriptionPlan)
        this.createTicketButton = true;
    },
    formatTicketDate(dateString) {
      return formatTicketDateTime(dateString);
    },
    async getTickets() {
      this.loading = true;
      const apiFilter = Object.assign({}, this.filters);
      if (!this.$vuetify.breakpoint.smAndDown) {
        apiFilter.page = this.options.page;
        apiFilter.limit = this.options.itemsPerPage;
        apiFilter.sortBy = this.options.sortBy[0];
        apiFilter.sortDesc = this.options.sortDesc[0];
      }
      let response = await api.ticket.getTickets(apiFilter);
      let allTickets = response.tickets;
      this.totalItems = parseInt(response.totalCount);
      for (let ticket of allTickets) {
        ticket.fullAddress =
          ticket.street_address +
          " " +
          ticket.place +
          " " +
          ticket.county +
          " " +
          ticket.state;
        ticket.center_name = this.getCenterName(ticket.center_id);
        if (ticket.center_name === "OHIO811") ticket.expiration_date = "N/A";
      }
      this.$store.commit("setTickets", [...response.tickets]);
      this.tickets = response.tickets.filter((ticket) => {
        return !ticket.center_ticket_id.startsWith("S");
      });

      this.loading = false;
    },
    getCenters() {
      const additionalCenters =
        this.$store.state.Company.company.additionalCenters || [];
      this.centers = [
        ...this.$store.state.Company.company.centers,
        ...additionalCenters,
      ];
    },
    async getUsers() {
      const user = this.$store.state.User.user;
      const allUsers = this.$store.state.User.companyUsers;
      if (user.persona !== "admin") {
        this.users.push(user);
      } else {
        this.users = allUsers;
      }
    },
    responseStatus(ticket) {
      let result = this.calculateResponsePercentage(ticket);
      let responseStatusText = "";
      responseStatusText = result.responded + "/" + result.total + " Received";

      return responseStatusText;
    },
    supportsResponses(item) {
      if (!item.receipt) return false;
      // returns the value of item.receipt.response_required if it is defined, and returns false if it is null or undefined.
      return item.receipt?.response_required ?? false;
    },
    hasMembers(receipt) {
      if (receipt) {
        if (receipt.members) {
          return receipt.members.length > 0;
        }
      }
      return false;
    },
    priorityIconColor(priority) {
      switch (priority) {
        case "RUSH":
          return "#fdd835";
        case "EMER":
          return "red";
        case "NONC":
          return "light-blue";
        default:
          return "grey";
      }
    },
    priorityIcon(priority) {
      switch (priority) {
        case "RUSH":
          // yellow circle exclamation
          return "mdi-bell";
        case "EMER":
          // red bell exclamation
          return "mdi-bell";
        case "NONC":
          // light blue circle exclamation
          return "mdi-bell";
        default:
          return "mdi-circle";
      }
    },
    statusIconColor(receipt, responses) {
      let responded = 0;
      let total = 0;
      if (responses) {
        responded = responses.length;
      }
      if (receipt?.members) {
        for (let member of receipt.members) {
          if (member.show) {
            total++;
          }
        }
      }
      if (total !== 0 && responded === total) {
        return "green";
      } else {
        return "red";
      }
    },
    ticketRevisionClass(item) {
      return "status-open-ticketrevision";
    },
    async refreshTicketData() {
      this.currentTab = "My Tickets";
      this.createTicketButton = false;
      if (this.tabs.length === 0 && this.subscriptionPlan) {
        await this.$store.commit("setCurrentStep", 1);
        this.createTicketButton = true;
        return;
      }
      this.getTickets();
      this.filteredTable = true;
    },
    showStatusDialog(ticket) {
      if (this.checkPermission("positiveResponseStatus")) {
        this.showTicketResponses = true;
        this.responsesDialog = true;
        this.selectedTicket = ticket;
        this.showResponses = this.supportsResponses(ticket);
        this.responsesPanel = false;
      }
    },
    showStatusPanel(ticket) {
      if (this.checkPermission("positiveResponseStatus")) {
        this.responsesDialog = false;
        this.showTicketResponses = true;
        this.responsesPanel = true;
        this.showResponses = this.supportsResponses(ticket);
        this.selectedTicket = ticket;
      }
    },
    hideTicketResponses() {
      this.showTicketResponses = false;
      this.responsesDialog = false;
      this.responsesPanel = false;
    },
    ticketSearch() {
      this.filters.user_id =
        typeof this.selectUserId !== "object" ? this.selectUserId : "";
      this.filters.center_id =
        typeof this.selectCenterId !== "object" ? this.selectCenterId : "";

      // loop through filters and check for nulls
      for (let key in this.filters) {
        if (this.filters[key] === null) {
          this.filters[key] = "";
        }
        if (this.filters[key])
          this.filters[key] = this.filters[key].toUpperCase();
      }

      this.filteredTable = true;
      this.getTickets(this.filters);
    },
    getCenterName(centerId) {
      let center = this.centers.find((center) => center.center_id === centerId);
      return center ? center.center_name : "";
    },
    getUserName(userId) {
      if (this.users.length === 0) {
        this.getUsers();
      }
      let user = this.users.find((user) => user.user_id === userId);
      return user ? user.name : "";
    },
    getAddress(ticket) {
      let address = "";
      if (ticket?.address) {
        address = ticket.address;
        if (ticket.city) {
          address += ", " + ticket.city;
        }
        if (ticket.state) {
          address += ", " + ticket.state;
        }
        if (ticket.zip) {
          address += " " + ticket.zip;
        }
      }
      return address;
    },
    setMobileCheckBoxState(model, item) {
      return model?.ticket_id === item?.ticket_id;
    },
    selectClear(value) {
      if (value === "user_id") {
        this.selectUserId = { name: "", user_id: "" };
        this.filters.user_id = "";
      }

      if (value === "center_id") {
        this.selectUserId = { center_name: "", center_id: "" };
        this.filters.center_id = "";
      }

      this.ticketSearch();
    },
    filterChange(value) {
      this.filters[value] = "";
      this.ticketSearch();
    },
    hasMembersToShow(receipt) {
      if (receipt) {
        if (receipt.members) {
          return this.totalMembersToShow(receipt.members) > 0;
        }
      }
      return false;
    },
    handleEmptyData(value) {
      return value?.trim() !== "" ? value : "Info Not on Ticket";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../../../scss/settings.scss";
.appbar-header.white {
  border: 1px solid $grey-lighten-2 !important;
}
.upperCase input {
  text-transform: uppercase;
}
.status-open-ticketrevision {
  text-decoration: none;
  /* color: #1976D2; */
}
.nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ticket-status {
  cursor: pointer;
  white-space: nowrap;
}
.empty-state {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.v-slide-group__prev {
  display: contents !important;
}
#responsePanel {
  position: absolute;
  top: 0;
  right: 0;
  width: 460px;
  height: calc(100vh);
  transform-origin: center center;
  max-width: 460px;
  margin-right: 0px;
  margin-top: -60px;
  margin-left: 0px;
  margin-bottom: -20px;
  z-index: 100;
  background-color: #fff;
  border-left: 1px solid #e0e0e0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.3s ease;
}

.v-data-table-header th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-data-table td {
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
