var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      !_vm.isTicketDetails
        ? _c(
            "div",
            [
              _vm.ticketSuccessAlert && !_vm.$vuetify.breakpoint.mdAndUp
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            dismissible: "",
                            "close-icon": "mdi-close",
                            text: "",
                            icon: "mdi-check-circle-outline",
                            dense: "",
                            type: "success",
                          },
                          on: { input: _vm.closeTicketAlert },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "my-auto font-weight-regular text-subtitle-2",
                            },
                            [_vm._v(_vm._s(_vm.ticketAlertMessage))]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex wrap text-h5 font-weight-medium",
                      class: _vm.$vuetify.breakpoint.mdAndUp ? "pl-8" : "pl-5",
                      attrs: {
                        cols: _vm.$vuetify.breakpoint.mdAndUp ? "" : "7",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.text === "map"
                              ? _vm.$t("ticketsMap")
                              : _vm.$t("tickets")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _vm.ticketSuccessAlert && _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "v-col",
                        { staticClass: "d-flex justify-end align-center pt-2" },
                        [
                          _c(
                            "v-alert",
                            {
                              attrs: {
                                dismissible: "",
                                "close-icon": "mdi-close",
                                text: "",
                                icon: "mdi-check-circle-outline",
                                dense: "",
                                type: "success",
                              },
                              on: { input: _vm.closeTicketAlert },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "my-auto font-weight-regular text-subtitle-2",
                                },
                                [_vm._v(_vm._s(_vm.ticketAlertMessage))]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-col",
                        {
                          staticClass:
                            "d-flex justify-end align-center pt-2 pb-5",
                        },
                        [
                          _vm.checkPermission("createTickets") &&
                          _vm.$vuetify.breakpoint.mdAndUp
                            ? _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "font-weight-regular text-none rounded-lg",
                                  attrs: { color: "primary" },
                                  on: { click: _vm.createTicket },
                                },
                                [
                                  _c("v-icon", [_vm._v("mdi-plus")]),
                                  _vm._v(" " + _vm._s(_vm.$t("createTicket"))),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.checkPermission("mapView")
                            ? _c(
                                "v-btn-toggle",
                                {
                                  staticClass:
                                    "d-flex justify-end ml-5 rounded-lg",
                                  class: _vm.$vuetify.breakpoint.mdAndUp
                                    ? "mr-5"
                                    : "mr-2",
                                  attrs: { borderless: false, mandatory: "" },
                                  model: {
                                    value: _vm.text,
                                    callback: function ($$v) {
                                      _vm.text = $$v
                                    },
                                    expression: "text",
                                  },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        height: "36px",
                                        value: "ticket",
                                        outlined: _vm.text !== "ticket",
                                        "aria-label": "Ticket View Button",
                                        color: "primary",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            size: "20",
                                            color:
                                              _vm.text !== "ticket"
                                                ? "primary"
                                                : "white",
                                          },
                                        },
                                        [_vm._v("mdi-land-rows-horizontal")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        height: "36px",
                                        value: "map",
                                        outlined: _vm.text !== "map",
                                        "aria-label": "Map View Button",
                                        color: "primary",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            size: "20",
                                            color:
                                              _vm.text !== "map"
                                                ? "primary"
                                                : "white",
                                          },
                                        },
                                        [_vm._v("mdi-map-outline")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                ],
                1
              ),
              _vm.text === "map"
                ? _c("ViewTicket", {
                    on: { onTicketDetails: _vm.onTicketDetails },
                  })
                : _vm._e(),
              _vm.text === "ticket"
                ? _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-app-bar",
                            {
                              staticClass: "appbar-header",
                              attrs: {
                                elevation: "0",
                                color: "white",
                                height: "40",
                              },
                            },
                            [
                              _c(
                                "v-tabs",
                                _vm._l(_vm.tabs, function (key, index) {
                                  return _c(
                                    "v-tab",
                                    {
                                      key: index,
                                      staticClass: "text-none tab-bar",
                                      class: _vm.$vuetify.breakpoint.mdAndUp
                                        ? "pl-8"
                                        : "pl-5",
                                      on: {
                                        change: function ($event) {
                                          _vm.currentTab = key
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(_vm._s(key)),
                                      key === "My Tickets"
                                        ? _c(
                                            "v-chip",
                                            {
                                              staticClass: "ma-2 pa-1",
                                              attrs: {
                                                color: "primary",
                                                outlined: "",
                                                pill: "",
                                                small: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.totalItems) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.currentTab === "My Tickets"
                        ? _c(
                            "div",
                            { staticClass: "mt-4" },
                            [
                              _c(
                                "v-card",
                                {
                                  class: _vm.$vuetify.breakpoint.smAndDown
                                    ? "mb-12"
                                    : null,
                                  attrs: { elevation: "0", width: "100%" },
                                },
                                [
                                  _vm.tickets.length > 0 || _vm.filteredTable
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mt-0",
                                              attrs: {
                                                "no-gutters":
                                                  this.$vuetify.breakpoint.xs,
                                              },
                                            },
                                            [
                                              this.$vuetify.breakpoint.mdAndUp
                                                ? _c(
                                                    "v-col",
                                                    [
                                                      _c("v-data-table", {
                                                        class: _vm.$vuetify
                                                          .breakpoint.mdAndUp
                                                          ? "ml-2"
                                                          : null,
                                                        attrs: {
                                                          id: "main-tickets-table",
                                                          "fixed-header": "",
                                                          headers: _vm.headers,
                                                          options: _vm.options,
                                                          loading: _vm.loading,
                                                          items: _vm.tickets,
                                                          "item-key":
                                                            "ticket_id",
                                                          height:
                                                            "calc(100vh - 270px)",
                                                          color: "primary",
                                                          "checkbox-color":
                                                            "primary",
                                                          "server-items-length":
                                                            _vm.totalItems,
                                                          "items-per-page": !_vm
                                                            .$vuetify.breakpoint
                                                            .mdAndUp
                                                            ? -1
                                                            : 15,
                                                          "hide-default-footer":
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .mdAndUp,
                                                          "loading-text":
                                                            _vm.$t("loading"),
                                                          "footer-props": {
                                                            "items-per-page-options":
                                                              _vm.perPageChoices,
                                                          },
                                                        },
                                                        on: {
                                                          "update:options":
                                                            function ($event) {
                                                              _vm.options =
                                                                $event
                                                            },
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            _vm.$vuetify
                                                              .breakpoint
                                                              .mdAndUp
                                                              ? {
                                                                  key: "body.prepend",
                                                                  fn: function () {
                                                                    return [
                                                                      _c("tr", [
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _c(
                                                                              "v-text-field",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "Date",
                                                                                    "clear-icon":
                                                                                      "mdi-close-circle",
                                                                                    clearable:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.ticketSearch,
                                                                                  "click:clear":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.filterChange(
                                                                                        "start_date"
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .filters
                                                                                        .start_date,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.filters,
                                                                                          "start_date",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "filters.start_date",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _c(
                                                                              "v-text-field",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                    "clear-icon":
                                                                                      "mdi-close-circle",
                                                                                    clearable:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.ticketSearch,
                                                                                  "click:clear":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.filterChange(
                                                                                        "center_ticket_id"
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .filters
                                                                                        .center_ticket_id,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.filters,
                                                                                          "center_ticket_id",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "filters.center_ticket_id",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _c(
                                                                              "v-text-field",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                    "clear-icon":
                                                                                      "mdi-close-circle",
                                                                                    clearable:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.ticketSearch,
                                                                                  "click:clear":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.filterChange(
                                                                                        "contact"
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .filters
                                                                                        .contact,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.filters,
                                                                                          "contact",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "filters.contact",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _c(
                                                                              "v-text-field",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                    "clear-icon":
                                                                                      "mdi-close-circle",
                                                                                    clearable:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.ticketSearch,
                                                                                  "click:clear":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.filterChange(
                                                                                        "contact_phone"
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .filters
                                                                                        .contact_phone,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.filters,
                                                                                          "contact_phone",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "filters.contact_phone",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _c(
                                                                              "v-text-field",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                    "clear-icon":
                                                                                      "mdi-close-circle",
                                                                                    clearable:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.ticketSearch,
                                                                                  "click:clear":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.filterChange(
                                                                                        "done_for"
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .filters
                                                                                        .done_for,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.filters,
                                                                                          "done_for",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "filters.done_for",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _c(
                                                                              "v-text-field",
                                                                              {
                                                                                staticClass:
                                                                                  "upperCase",
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                    "clear-icon":
                                                                                      "mdi-close-circle",
                                                                                    clearable:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.ticketSearch,
                                                                                  "click:clear":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.filterChange(
                                                                                        "priority"
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .filters
                                                                                        .priority,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.filters,
                                                                                          "priority",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "filters.priority",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _c(
                                                                              "v-text-field",
                                                                              {
                                                                                staticClass:
                                                                                  "upperCase",
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                    "clear-icon":
                                                                                      "mdi-close-circle",
                                                                                    clearable:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.ticketSearch,
                                                                                  "click:clear":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.filterChange(
                                                                                        "type"
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .filters
                                                                                        .type,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.filters,
                                                                                          "type",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "filters.type",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "td"
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _c(
                                                                              "v-text-field",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "Date",
                                                                                    "clear-icon":
                                                                                      "mdi-close-circle",
                                                                                    clearable:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.ticketSearch,
                                                                                  "click:clear":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.filterChange(
                                                                                        "expiration_date"
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .filters
                                                                                        .expiration_date,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.filters,
                                                                                          "expiration_date",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "filters.expiration_date",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _c(
                                                                              "v-select",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    items:
                                                                                      _vm.users,
                                                                                    "item-text":
                                                                                      "name",
                                                                                    "item-value":
                                                                                      "user_id",
                                                                                    "clear-icon":
                                                                                      "mdi-close-circle",
                                                                                    clearable:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.ticketSearch,
                                                                                  "click:clear":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.selectClear(
                                                                                        "user_id"
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm.selectUserId,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.selectUserId =
                                                                                          $$v
                                                                                      },
                                                                                    expression:
                                                                                      "selectUserId",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _c(
                                                                              "v-select",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    items:
                                                                                      _vm.centers,
                                                                                    "item-text":
                                                                                      "center_name",
                                                                                    "item-value":
                                                                                      "center_id",
                                                                                    "clear-icon":
                                                                                      "mdi-close-circle",
                                                                                    clearable:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.ticketSearch,
                                                                                  "click:clear":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.selectClear(
                                                                                        "center_id"
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm.selectCenterId,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.selectCenterId =
                                                                                          $$v
                                                                                      },
                                                                                    expression:
                                                                                      "selectCenterId",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _c(
                                                                              "v-text-field",
                                                                              {
                                                                                staticClass:
                                                                                  "upperCase",
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                    "clear-icon":
                                                                                      "mdi-close-circle",
                                                                                    clearable:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.ticketSearch,
                                                                                  "click:clear":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.filterChange(
                                                                                        "street_address"
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .filters
                                                                                        .street_address,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.filters,
                                                                                          "street_address",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "filters.street_address",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _c(
                                                                              "v-text-field",
                                                                              {
                                                                                staticClass:
                                                                                  "upperCase",
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                    "clear-icon":
                                                                                      "mdi-close-circle",
                                                                                    clearable:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.ticketSearch,
                                                                                  "click:clear":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.filterChange(
                                                                                        "place"
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .filters
                                                                                        .place,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.filters,
                                                                                          "place",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "filters.place",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _c(
                                                                              "v-text-field",
                                                                              {
                                                                                staticClass:
                                                                                  "upperCase",
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                    "clear-icon":
                                                                                      "mdi-close-circle",
                                                                                    clearable:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.ticketSearch,
                                                                                  "click:clear":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.filterChange(
                                                                                        "county"
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .filters
                                                                                        .county,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.filters,
                                                                                          "county",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "filters.county",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _c(
                                                                              "v-text-field",
                                                                              {
                                                                                staticClass:
                                                                                  "upperCase",
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                    "clear-icon":
                                                                                      "mdi-close-circle",
                                                                                    clearable:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.ticketSearch,
                                                                                  "click:clear":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.filterChange(
                                                                                        "state"
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .filters
                                                                                        .state,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.filters,
                                                                                          "state",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "filters.state",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                }
                                                              : null,
                                                            {
                                                              key: "item.contact",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "nowrap",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.handleEmptyData(
                                                                              item.contact
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "item.contact_phone",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "nowrap",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.handleEmptyData(
                                                                              item.contact_phone
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "item.done_for",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "nowrap",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.handleEmptyData(
                                                                              item.done_for
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "item.start_date",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "nowrap",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.formatTicketDate(
                                                                              item.start_date
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "item.fullAddress",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "nowrap",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.fullAddress
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "item.center_ticket_id",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      staticClass:
                                                                        "nowrap primary--text",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.onTicketDetails(
                                                                              item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.center_ticket_id
                                                                          ) +
                                                                          " - " +
                                                                          _vm._s(
                                                                            item.revision
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "item.priority",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "nowrap ma-2",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "x-small":
                                                                                "",
                                                                              color:
                                                                                _vm.priorityIconColor(
                                                                                  item.priority
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.priorityIcon(
                                                                                  item.priority
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.priority
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "item.ticket_type",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.ticket_type
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "item.state",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "nowrap",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.state
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "item.status",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _vm.supportsResponses(
                                                                    item
                                                                  )
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  text: "",
                                                                                  small:
                                                                                    "",
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    _vm
                                                                                      .$vuetify
                                                                                      .breakpoint
                                                                                      .mdAndUp
                                                                                      ? _vm.showStatusPanel(
                                                                                          item
                                                                                        )
                                                                                      : _vm.showStatusDialog(
                                                                                          item
                                                                                        )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      left: "",
                                                                                      "x-small":
                                                                                        "",
                                                                                      color:
                                                                                        _vm.calculateResponsePercentage(
                                                                                          item
                                                                                        )
                                                                                          .value ===
                                                                                        100
                                                                                          ? "green"
                                                                                          : "red",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " mdi-circle "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.responseStatus(
                                                                                      item
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  text: "",
                                                                                  small:
                                                                                    "",
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    _vm
                                                                                      .$vuetify
                                                                                      .breakpoint
                                                                                      .mdAndUp
                                                                                      ? _vm.showStatusPanel(
                                                                                          item
                                                                                        )
                                                                                      : _vm.showStatusDialog(
                                                                                          item
                                                                                        )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " View Members "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "item.expiration_date",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "nowrap",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.formatTicketDate(
                                                                              item.expiration_date
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "item.street_address",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "nowrap",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.street_address
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "item.user_id",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "nowrap",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.getUserName(
                                                                            item.user_id
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "item.place",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "nowrap",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.place
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "item.county",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "nowrap",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.county
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "item.center_id",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "nowrap",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.getCenterName(
                                                                              item.center_id
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value: _vm.selected,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.selected = $$v
                                                          },
                                                          expression:
                                                            "selected",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              this.$vuetify.breakpoint.smAndDown
                                                ? _c(
                                                    "v-col",
                                                    { staticClass: "pb-14" },
                                                    [
                                                      _c("v-data-iterator", {
                                                        attrs: {
                                                          id: "ticket-cards",
                                                          items: _vm.tickets,
                                                          "item-key":
                                                            "ticket_id",
                                                          "fixed-header": "",
                                                          "show-select": "",
                                                          "loading-text":
                                                            "Loading items...",
                                                          "no-data-text": "",
                                                          page: _vm.options
                                                            .page,
                                                          loading: _vm.loading,
                                                          options: _vm.options,
                                                          "items-per-page":
                                                            _vm.options
                                                              .itemsPerPage,
                                                        },
                                                        on: {
                                                          "update:page":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.options,
                                                                "page",
                                                                $event
                                                              )
                                                            },
                                                          "update:options":
                                                            function ($event) {
                                                              _vm.options =
                                                                $event
                                                            },
                                                          "toggle-select-all":
                                                            _vm.mobileSelectAll,
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "header",
                                                              fn: function () {
                                                                return [
                                                                  _c(
                                                                    "TicketsMobileDataToolbar",
                                                                    {
                                                                      attrs: {
                                                                        "display-toolbar":
                                                                          !!_vm
                                                                            .tickets
                                                                            .length,
                                                                        "selected-tickets":
                                                                          _vm.selected,
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "selectCheckbox",
                                                                              fn: function () {
                                                                                return [
                                                                                  _c(
                                                                                    "v-checkbox",
                                                                                    {
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm.mobileSelectAll,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.mobileSelectAll =
                                                                                                $$v
                                                                                            },
                                                                                          expression:
                                                                                            "mobileSelectAll",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              },
                                                                              proxy: true,
                                                                            },
                                                                          ],
                                                                          null,
                                                                          false,
                                                                          380368664
                                                                        ),
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                            {
                                                              key: "default",
                                                              fn: function (
                                                                props
                                                              ) {
                                                                return _vm._l(
                                                                  props.items,
                                                                  function (
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "TicketsMobileCard",
                                                                      {
                                                                        key: item.ticket_id,
                                                                        attrs: {
                                                                          item: item,
                                                                          "is-selected":
                                                                            !!_vm.selected.find(
                                                                              function (
                                                                                ticket
                                                                              ) {
                                                                                return (
                                                                                  ticket.ticket_id ===
                                                                                  item.ticket_id
                                                                                )
                                                                              }
                                                                            ),
                                                                          "supports-responses":
                                                                            _vm.supportsResponses(
                                                                              item
                                                                            ),
                                                                        },
                                                                        on: {
                                                                          showStatusDialog:
                                                                            _vm.showStatusDialog,
                                                                          onTicketDetails:
                                                                            _vm.onTicketDetails,
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "selectCheckbox",
                                                                                fn: function () {
                                                                                  return [
                                                                                    _c(
                                                                                      "v-checkbox",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            value:
                                                                                              item,
                                                                                            multiple: true,
                                                                                            "value-comparator":
                                                                                              _vm.setMobileCheckBoxState,
                                                                                          },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm.selected,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.selected =
                                                                                                  $$v
                                                                                              },
                                                                                            expression:
                                                                                              "selected",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                                proxy: true,
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      }
                                                                    )
                                                                  }
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1369426834
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              !_vm.loading &&
                              _vm.tickets.length === 0 &&
                              !_vm.filteredTable &&
                              _vm.checkPlan
                                ? _c(
                                    "div",
                                    { staticClass: "mt-4 empty-state" },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          class: _vm.$vuetify.breakpoint
                                            .smAndDown
                                            ? "mb-12"
                                            : null,
                                          attrs: {
                                            elevation: "0",
                                            width: "100%",
                                          },
                                        },
                                        [
                                          _c("v-img", {
                                            staticClass: "mx-auto my-2",
                                            attrs: {
                                              height: "auto",
                                              src: require("@/assets/images/ticket-empty-state.svg"),
                                              "max-width": "100",
                                              "min-width": "100",
                                            },
                                          }),
                                          _c("v-row", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "h6 font-weight-medium grey--text text--darken-4 mx-auto my-2",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("noTicketsCreated")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("v-row", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "subtitle-2 font-weight-regular grey--text text--darken-2 mx-auto text-center",
                                                staticStyle: {
                                                  display: "block",
                                                },
                                              },
                                              [
                                                _vm.$vuetify.breakpoint.mdAndUp
                                                  ? [
                                                      _vm._v(
                                                        " Click the create ticket button to create your first ticket. "
                                                      ),
                                                    ]
                                                  : [
                                                      _vm._v(
                                                        " Click the create ticket button to "
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        " create your first ticket. "
                                                      ),
                                                    ],
                                              ],
                                              2
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.currentTab == "Approval Queue"
                        ? _c(
                            "div",
                            { staticClass: "mt-4" },
                            [_c("PendingQueue")],
                            1
                          )
                        : _vm._e(),
                      _vm.currentTab == "Awaiting Approval"
                        ? _c(
                            "div",
                            { staticClass: "mt-4" },
                            [
                              _c("AwaitingQueue", {
                                on: { createTicket: _vm.createTicket },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.currentTab === "Scheduled Tickets"
                        ? _c("ScheduledTicketQueue", {
                            on: { createTicket: _vm.createTicket },
                          })
                        : _vm._e(),
                      _vm.currentTab == "Suspend Tickets"
                        ? _c("SuspendTicketQueue", { staticClass: "mt-4" })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$vuetify.breakpoint.smAndDown &&
              _vm.checkPermission("createTickets")
                ? _c(
                    "v-bottom-navigation",
                    {
                      staticClass: "px-2 py-4",
                      attrs: { fixed: "", horizontal: "", height: "70" },
                      model: {
                        value: _vm.bottomNavbar,
                        callback: function ($$v) {
                          _vm.bottomNavbar = $$v
                        },
                        expression: "bottomNavbar",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "px-4 py-2" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-none rounded-lg font-weight-regular text-h6 white--text",
                              attrs: {
                                color: "primary",
                                block: "",
                                height: "50",
                              },
                              on: { click: _vm.createTicket },
                            },
                            [
                              _vm._v(_vm._s(_vm.$t("createTicket"))),
                              _c("v-icon", [_vm._v("mdi-plus")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c("TicketDetails", {
            attrs: { "ticket-details": _vm.ticketRowData },
            on: {
              createTicket: _vm.createTicket,
              hideTicketDetails: _vm.hideTicketDetails,
              secondaryFnSuccessAlert: _vm.secondaryFnSuccessAlert,
            },
          }),
      _vm.responsesDialog
        ? _c(
            "v-layout",
            { attrs: { row: "", "justify-center": "" } },
            [
              _c(
                "v-dialog",
                {
                  attrs: { fullscreen: "", "hide-overlay": "" },
                  model: {
                    value: _vm.responsesDialog,
                    callback: function ($$v) {
                      _vm.responsesDialog = $$v
                    },
                    expression: "responsesDialog",
                  },
                },
                [
                  _c("MembersPanel", {
                    attrs: {
                      ticket: _vm.selectedTicket,
                      "show-responses": _vm.showResponses,
                    },
                    on: { hideTicketResponses: _vm.hideTicketResponses },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.responsesPanel
        ? _c("v-layout", { attrs: { row: "", "justify-end": "" } }, [
            _c(
              "div",
              { attrs: { id: "responsePanel" } },
              [
                _c("MembersPanel", {
                  attrs: {
                    ticket: _vm.selectedTicket,
                    "show-responses": _vm.showResponses,
                  },
                  on: { hideTicketResponses: _vm.hideTicketResponses },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }