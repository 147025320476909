var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { attrs: { fluid: "", "pa-0": "" } }, [
    !_vm.$store.state.Company.company.subscription_id
      ? _c(
          "div",
          { staticClass: "mt-4 empty-state" },
          [
            _c("v-progress-circular", {
              attrs: { size: 70, width: 7, color: "green", indeterminate: "" },
            }),
          ],
          1
        )
      : _c(
          "div",
          [
            _vm.$store.state.Company.company.subscription_id && _vm.viewTickets
              ? _c("TicketMain")
              : _vm._e(),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }